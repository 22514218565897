import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './abastecidaForm';
import Select from '../common/form/select';
import ReactSelect from 'react-select';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import { 
	setModoTela, initForm, buscarImagens, salvar, salvarConferido, salvarAtua, setFiltro,
	excluir, getLista, getListaPosto, getListaMotorista, getListaVeiculo, getListaFormaPagamento 
} from './abastecidaActions';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';

class Abastecida extends Component {

	state = {
		linhaSelecionada: null,
		conferido: false,
		pesquisar: '',
		atua: 3
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaPosto();
		this.props.getListaMotorista();
		this.props.getListaVeiculo();
		this.props.getListaFormaPagamento();
    }

    render() {
        return (
            <div>
				
				<Aguardando aguardando={this.props.aguardando} />

                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista = this.props.lista.filter(item => 
			this.props.sessao && (!this.props.sessao.id_motorista || this.props.sessao.id_motorista == item.id_motorista)
		).filter(item => this.state.id_status == null || item.id_status == this.state.id_status)
		.filter(item => this.state.conferido == null ? true : (this.state.conferido ? item.conferido : !item.conferido))
		.filter(item => !this.state.atua || this.state.atua == 3 || (item.lancado_atua && this.state.atua == 1) || (!item.lancado_atua && this.state.atua == 2))
		.filter(item => {
			if (this.state.pesquisar
				&& !(`${item.nome_motorista} ${item.placa_veiculo} ${item.nome_fornecedor} ${item.nome_cidade} ${item.nota_fiscal} ${item.nome_forma_pagamento} ${item.nome_abastecida_produto}
					${item.id_negociacao ? (item.dentro_prazo ? 'Dentro Prazo' : 'Vencida') : '-'} ${item.nome_status}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			return true;
		});

		let totalExportacao = {
			preco: 0,
			preco_negociado: 0,
			diferenca: 0,
			valor: 0,
			quantidade: 0
		};
		let listaExportacao = lista.map(item => {

			totalExportacao = {
				preco: totalExportacao.preco + (parseFloat(item.preco || 0) * parseFloat(item.quantidade || 0)),
				preco_negociado: totalExportacao.preco_negociado + (parseFloat(item.preco_negociado || 0) * parseFloat(item.quantidade || 0)),
				diferenca: totalExportacao.diferenca + (parseFloat(item.diferenca || 0) * parseFloat(item.quantidade || 0)),
				valor: totalExportacao.valor + parseFloat(item.valor || 0),
				quantidade: totalExportacao.quantidade + parseFloat(item.quantidade || 0)
			};

			return {
				...item,
				datahora_inclusao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao),
				datahora_abastecida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida),
				data_posto_prazo: DateFormat.formatarDataSqlParaTela(item.data_posto_prazo),
				preco: parseFloat(item.preco || 0),
				preco_negociado: parseFloat(item.preco_negociado || 0),
				diferenca: parseFloat(item.diferenca || 0),
				negociacao: item.id_negociacao ? (item.dentro_prazo ? 'Dentro Prazo' : 'Vencida') : '-',
				valor: parseFloat(item.valor || 0),
				quantidade: parseFloat(item.quantidade || 0),
				media_odometro: parseFloat(item.media_odometro || 0),
				conferido: item.conferido ? 'Sim' : 'Não',
				lancado_atua: item.lancado_atua ? 'Sim' : 'Não'
			};
		});

		listaExportacao.push({
			preco: totalExportacao.preco / totalExportacao.quantidade,
			preco_negociado: totalExportacao.preco_negociado / totalExportacao.quantidade,
			diferenca: totalExportacao.diferenca / totalExportacao.quantidade,
			quantidade: totalExportacao.quantidade,
			valor: totalExportacao.valor
		});

		console.log(totalExportacao);

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								disabled={this.props.permissoes.acesso_despesa != 2}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ 
										datahora_abastecida: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
										id_motorista: this.props.sessao && this.props.sessao.id_motorista ? this.props.sessao.id_motorista : null
									});
								}} />
						</Grid>
						{(this.props.sessao && !this.props.sessao.id_motorista) &&
						<Grid cols='6 6 4 2'>
							<ExcelFile
								filename={'Abastecidas'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={listaExportacao} name='Abastecidas'>
									<ExcelColumn label='Data Inclusão' value='datahora_inclusao' />
									<ExcelColumn label='Data Documento' value='datahora_abastecida' />
									<ExcelColumn label='Motorista' value='nome_motorista' />
									<ExcelColumn label='Veículo' value='placa_veiculo' />
									<ExcelColumn label='Fornecedor' value='nome_fornecedor' />
									<ExcelColumn label='Cidade' value='nome_cidade' />
									<ExcelColumn label='Nota Fiscal' value='nota_fiscal' />
									<ExcelColumn label='Pagamento' value='nome_forma_pagamento' />
									<ExcelColumn label='Prazo Pgto' value='data_posto_prazo' />
									<ExcelColumn label='Produto' value='nome_abastecida_produto' />
									<ExcelColumn label='Quantidade' value='quantidade' type='number' />
									<ExcelColumn label='Preço Pago' value='preco' type='number' />
									<ExcelColumn label='Preço Negociado' value='preco_negociado' type='number' />
									<ExcelColumn label='Diferença' value='diferenca' type='number' />
									<ExcelColumn label='Negociação' value='negociacao' />
									<ExcelColumn label='Status' value='nome_status' />
									<ExcelColumn label='Valor' value='valor' type='number' />
									<ExcelColumn label='Media' value='media_odometro' type='number' />
									<ExcelColumn label='Conferido' value='conferido' />
									<ExcelColumn label='Atua' value='lancado_atua' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>}
						{(this.props.sessao && !this.props.sessao.id_motorista) &&
						<Grid cols='6 6 4 2'>
							<ExcelFile
								filename={'Abastecidas'}
								element={
									<Button
										text='Exportar Resumido'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									quantidade: parseFloat(item.quantidade),
									preco: parseFloat(item.preco),
									valor: parseFloat(item.valor)
								}))} name='Abastecidas'>
									<ExcelColumn label='Posto' value='nome_fornecedor' />
									<ExcelColumn label='Produto' value='nome_produto' />
									<ExcelColumn label='Quantidade' value='quantidade' type='number' />
									<ExcelColumn label='Vlr Unitário' value='preco' type='number' />
									<ExcelColumn label='Total' value='valor' type='number' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>}
					</Row>
					<Row style={{ marginTop: 8 }}>
						<Select
							label='Negociação'
							cols='12 6 4 3'
							options={this.props.listaStatus}
							value={this.state.id_status}
							onChange={item => {
								this.setState({ ...this.state, id_status: item })
							}} />

						<Select
							label='Conferido'
							cols='12 6 4 3'
							options={[{ id: '1', valor: 'Sim' }, { id: '2', valor: 'Não' }, { id: '3', valor: 'Ambos' }]}
							value={this.state.conferido == null ? 3 : (this.state.conferido ? 1 : 2)}
							onChange={item => {
								this.setState({ ...this.state, conferido: item == 3 ? null : item == 1 })
							}} />

						<Select
							label='Atua'
							cols='12 6 4 3'
							options={[{ id: '1', valor: 'Sim' }, { id: '2', valor: 'Não' }, { id: '3', valor: 'Ambos' }]}
							value={this.state.atua}
							onChange={item => {
								this.setState({ ...this.state, atua: item })
							}} />
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<LabelAndInputMask
							label='Data Inicial' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
							}} />
							
						<Grid cols='6 6 4 2' style={{ paddingTop: 28 }}>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista();
								}} />
						</Grid>
					</Row>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
							<Tr>
								<Th alignCenter>Data Inclusão</Th>
								<Th alignCenter>Data Documento</Th>
								<Th>Motorista</Th>
								<Th>Veículo</Th>
								<Th>Fornecedor</Th>
								<Th>Cidade</Th>
								<Th alignCenter>Nota Fiscal</Th>
								<Th>Pagamento</Th>
								<Th alignCenter>Prazo Pgto</Th>
								<Th alignCenter>Produto</Th>
								<Th alignRight>Preço Pago</Th>
								<Th alignRight>Preço Negociado</Th>
								<Th alignRight>Diferença</Th>
								<Th alignCenter>Negociação</Th>
								<Th alignCenter>Status</Th>
								<Th alignRight>Valor</Th>
								<Th alignRight>Media</Th>
								<Th alignCenter>Conferido</Th>
								<Th alignCenter>Atua</Th>
								<Th></Th>
							</Tr>

						<TBody>
							{lista.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida)}</Td>
									<Td>{item.nome_motorista}</Td>
									<Td>{item.placa_veiculo}</Td>
									<Td>{item.nome_fornecedor}</Td>
									<Td>{item.nome_cidade}</Td>
									<Td alignCenter>{item.nota_fiscal}</Td>
									<Td>{item.nome_forma_pagamento}</Td>
									<td alignCenter>{DateFormat.formatarDataSqlParaTela(item.data_posto_prazo)}</td>
									<Td alignCenter>{item.nome_abastecida_produto}</Td>
									<Td alignRight>{FormatUtils.formatarValorTela(item.preco, 3)}</Td>
									<Td alignRight>{FormatUtils.formatarValorTela(item.preco_negociado, 2)}</Td>
									<Td alignRight>{FormatUtils.formatarValorTela(item.diferenca, 2)}</Td>
									<Td alignCenter>{item.id_negociacao ? (item.dentro_prazo ? 'Dentro Prazo' : 'Vencida') : '-'}</Td>
									<Td alignCenter style={{ fontWeight: 'bold', color: item.id_status == 0 ? '#000' : '#fff', backgroundColor: ['#ff0', '#00c853', '#29b6f6', '#ff5252'][item.id_status] }}>{item.nome_status}</Td>
									<Td alignRight>{FormatUtils.formatarValorTela(item.valor, 2)}</Td>
									<Td alignRight>{item.completou ? FormatUtils.formatarValorTela(item.media_odometro, 2) : ''}</Td>
                                    <Td>
										{(this.props.sessao && !this.props.sessao.id_motorista) &&
										<select
											className='form-control'
											readOnly={item.conferido ? 'readOnly' : null}
											value={item.conferido ? 1 : 2}
											onChange={data => {
												this.props.salvarConferido({
													...item,
													datahora_abastecida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2),
													conferido: data.target.value == 1
												});
											}}>
											<option value='1'>Sim</option>
											<option value='2'>Não</option>
										</select>}
									</Td>
									<Td>
										{(this.props.sessao && !this.props.sessao.id_motorista) &&
										<select
											className='form-control'
											readOnly={item.lancado_atua ? 'readOnly' : null}
											value={item.lancado_atua ? 1 : 2}
											onChange={data => {
												this.props.salvarAtua({
													...item,
													datahora_abastecida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2),
													lancado_atua: data.target.value == 1
												});
											}}>
											<option value='1'>Sim</option>
											<option value='2'>Não</option>
										</select>}
									</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_despesa != 2}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora_abastecida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.initForm({
													...item,
													datahora_abastecida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.buscarImagens(item.id);
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_despesa != 2}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													datahora_abastecida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.initForm({
													...item,
													datahora_abastecida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_abastecida),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.buscarImagens(item.id);
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.abastecida.modoTela,
	lista: state.abastecida.lista,
	aguardando: state.abastecida.aguardando,
	listaStatus: state.abastecida.listaStatus,
	filtro: state.abastecida.filtro,
	permissoes: state.usuario.permissoes
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, buscarImagens, salvar, salvarConferido, salvarAtua, setFiltro,
	excluir, getLista, getListaPosto, getListaMotorista, getListaVeiculo, getListaFormaPagamento 
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Abastecida);
