import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import DashboardManutencaoReducer from '../dashboardManutencao/dashboardManutencaoReducer';
import DashboardFinanceiroReducer from '../dashboardFinanceiro/dashboardFinanceiroReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import ViagemReducer from '../viagem/viagemReducer';
import ManutencaoReducer from '../manutencao/manutencaoReducer';
import CotacaoReducer from '../cotacao/cotacaoReducer';
import PostoReducer from '../posto/postoReducer';
import PontoTipoReducer from '../pontoTipo/pontoTipoReducer';
import PontoReducer from '../ponto/pontoReducer';
import PostoNegociacaoReducer from '../posto/postoNegociacao/postoNegociacaoReducer';
import TipoDespesaReducer from '../tipoDespesa/tipoDespesaReducer';
import ViagemLancamentoReducer from '../viagemLancamento/viagemLancamentoReducer';
import DespesaViagemReducer from '../despesaViagem/despesaViagemReducer';
import ChecklistReducer from '../checklist/checklistReducer';
import ChecklistPerguntasReducer from '../checklist/checklistPerguntas/checklistPerguntasReducer';
import DespesaProdutoReducer from '../despesaProduto/despesaProdutoReducer';
import AbastecidaReducer from '../abastecida/abastecidaReducer';
import ValeReducer from '../vale/valeReducer';
import DevolucaoReducer from '../devolucao/devolucaoReducer';
import VeiculoTipoReducer from '../veiculoTipo/veiculoTipoReducer';
import VeiculoReducer from '../veiculo/veiculoReducer';
import ProdutoReducer from '../produto/produtoReducer';
import PlanejamentoViagemReducer from '../planejamentoViagem/planejamentoViagemReducer';
import RedeReducer from '../rede/redeReducer';
import FormaPagamentoReducer from '../formaPagamento/formaPagamentoReducer';
import RotaReducer from '../rota/rotaReducer';
import MotoristaReducer from '../motorista/motoristaReducer';
import SetorReducer from '../setor/setorReducer';
import OcorrenciaReducer from '../ocorrencia/ocorrenciaReducer';
import OrdemServicoReducer from '../ordemServico/ordemServicoReducer';
import ContaBancariaReducer from '../contaBancaria/contaBancariaReducer';
import NotificacaoReducer from '../notificacao/notificacaoReducer';
import MultaReducer from '../multa/multaReducer';
import VeiculoAlertaReducer from '../veiculoAlerta/veiculoAlertaReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
	dashboard: DashboardReducer,
	dashboardManutencao: DashboardManutencaoReducer,
	dashboardFinanceiro: DashboardFinanceiroReducer,
	usuario: UsuarioReducer,
	notificacao: NotificacaoReducer,
	viagem: ViagemReducer,
	manutencao: ManutencaoReducer,
    cotacao: CotacaoReducer,
	posto: PostoReducer,
	pontoTipo: PontoTipoReducer,
	ponto: PontoReducer,
	postoNegociacao: PostoNegociacaoReducer,
	rede: RedeReducer,
	formaPagamento: FormaPagamentoReducer,
    tipoDespesa: TipoDespesaReducer,
    viagemLancamento: ViagemLancamentoReducer,
    despesaViagem: DespesaViagemReducer,
    checklist: ChecklistReducer,
    checklistPerguntas: ChecklistPerguntasReducer,
    despesaProduto: DespesaProdutoReducer,
	abastecida: AbastecidaReducer,
	rota: RotaReducer,
	vale: ValeReducer,
	devolucao: DevolucaoReducer,
	veiculoTipo: VeiculoTipoReducer,
	veiculo: VeiculoReducer,
	produto: ProdutoReducer,
    planejamentoViagem:PlanejamentoViagemReducer,
	motorista: MotoristaReducer,
	setor: SetorReducer,
	ocorrencia: OcorrenciaReducer,
	ordemServico: OrdemServicoReducer,
	contaBancaria: ContaBancariaReducer,
	multa: MultaReducer,
	veiculoAlerta: VeiculoAlertaReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
