import React, { useRef, useEffect, useState } from 'react';

const Camera = (props) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [photo, setPhoto] = useState(null);
  const [flashOn, setFlashOn] = useState(false);
  const [track, setTrack] = useState(null);

  useEffect(() => {
    const startCamera = async () => {
      try {
        // Solicita acesso à câmera
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setTrack(stream.getVideoTracks()[0]);
        }
      } catch (error) {
        console.error("Erro ao acessar a câmera:", error);
      }
    };

    startCamera();

    return () => {
      // Limpa o stream ao desmontar o componente
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  const takePhoto = () => {
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const video = videoRef.current;
      const context = canvas.getContext('2d');

      // Define as dimensões do canvas com base no vídeo
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Desenha o quadro atual do vídeo no canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Converte o conteúdo do canvas em uma URL de imagem (base64)
      const photoData = canvas.toDataURL('image/png');
      props.eventoFoto(photoData);
    }
  };

  const isFlash = () => {

    if (track) {
      try {

        const capabilities = track.getCapabilities();

        return capabilities.torch || false;
      } catch (error) {
        console.error("Erro ao ativar o flash:", error);
      }
    }
    
    return false;
  };

  const toggleFlash = async () => {
    if (track) {
      try {
        const capabilities = track.getCapabilities();
        if (capabilities.torch) {
          await track.applyConstraints({
            advanced: [{ torch: !flashOn }]
          });
          setFlashOn(!flashOn);
        } else {
          alert("Seu dispositivo não suporta flash na câmera.");
        }
      } catch (error) {
        console.error("Erro ao ativar o flash:", error);
      }
    }
  };

  return (
    <div>
      <div style={{ display: photo ? 'none' : 'block' }}>
        <video ref={videoRef} autoPlay playsInline style={{ width: '100%', height: window.innerHeight - 200, maxWidth: window.innerWidth - 10 }} />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button onClick={takePhoto} 
                style={{
                    marginTop: 10,
                    margilLeft: track && isFlash() ? 40 : 0,
                    padding: 10,
                    backgroundColor: 'grey',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    width: 50,
                    height: 50,
                }}>
                <i class='fa fa-camera' style={{ fontSize: 24 }}></i>                
            </button>
            
            {(track && isFlash()) &&
            <button onClick={toggleFlash} 
                style={{
                  marginTop: 10,
                  padding: 10,
                  backgroundColor: 'grey',
                  color: 'white',
                  border: 'none',
                  borderRadius: '50%',
                  width: 35,
                  height: 35,
              }}>
              <i class='fas fa-bolt' style={{ fontSize: 18 }}></i>
            </button>}
        </div>
      </div>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default Camera;
